import './footer.css'

const Footer = () => {
    return (
        <div className="footer_block">
            <div className='footer_line'></div>
            <div className='footer_title-block'>
                <h2>Copyright © 2018 all rights reserved</h2>
            </div>
        </div>
    )
}

export default Footer;